.validationBox {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1em;
    transform: translate(-50%, -50%);
    border-radius: 1em !important;
    background-color: #ffffff;
}

.validationBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4em;

    font-weight: 500;
    font-size: 1em;
}

.validButtonBox {
    width: 15em;
    font-size: 1em;
}

.message {
    width: 15em;
    text-align: center;
    font-size: 1em;
    margin-bottom: 0.3em;
}