.menuLetters {
  position: fixe;
  width: 3em;
  height: 48em;
  right: 4em;
  top: 4em;
  background-color: rgba(127, 127, 127, 0.25);

}

.menuLetterButton,
.menuLetterButton2 {
  width: 3em;
  height: 1.71em;
  background-color: none;
  border: 1px solid 3465a4;
  border-bottom-right-radius: 0.8em;
  text-align: center;
  background-color: #b4c7dc;
  border: 1px solid #3465a4;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 999;
}

.menuLetterButton {
  border-bottom: 0;
}

.menuLetterButton>a,
.menuLetterButton2>a {
  line-height: 1.56em;
  padding-right: 10px;
  color: #3465a4;
  font-weight: 500;
}

.menuLetterButton>h1,
.menuLetterButton2>a {
  font-size: 1.5em;
  line-height: 0.9em;
}

.menuLetterButton2 .navLink2 {
  transform: translate(0, -10px);
}