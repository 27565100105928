.header {
  width: 33em !important;
  height: 3em;
  top: 0;
  left: 0 !important;
  color: #ffffff;
  background-color: #3465a4 !important;
  z-index: 1200;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;

}

.appBar {
  width: 33em !important;
  height: 3em;
  top: 0;
  left: 0 !important;
  text-align: center;
  background-color: #3465a4;
}

.appBar>a {
  color: white;
  margin-left: 2em;
}

.menuTitre {
  line-height: 1.5em !important;
  font-size: 1.5em !important;
  text-align: center !important;
  transform: translate(-2px, -2px) !important;
}

.menu {
  height: 3em !important;
  display: block;
  font-size: medium !important;

}

.menu>button {
  height: calc(4em - 1px) !important;
  display: -webkit-inline-box !important;
  transform: translate(-2px, -2px);
}

/*
.intervalleH {
  width: 33em;
  height: 1em;
  margin: 0;
  padding: 0;
  left: 0;
  top: 3em;
  background-color: #ffffff;
}

.appBar {
  height: 3em;
  top: 0;
  left: 0;
}

.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #3465a4;
}
*/