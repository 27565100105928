.footBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  bottom: 0;
  height: 4em;

  background-color: #3465a4;
}

.intervalleH3 {
  width: 33em;
  height: 3em;
  background-color: rgba(255, 255, 255, 0);
}

.footDiv {
  width: 33em;
  height: 4em;
}

.footDiv>p {
  line-height: 4em;
  color: #ffffff;
}