.mainBox2 {
  width: 29em;
  height: 48em;
}

.mainBox3 {
  z-index: 1200;
  width: 29em;
  height: 43em;
  z-index: 1090;
  background-color: #3465a4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.itemsList {
  display: flex;
  flex-direction: column;
  width: 27em;
  height: 41em;
  background-color: #b4c7dc;
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  margin: 0.5em;
  background-color: #3465a4;
}

.item>a>p {
  height: 2em;
  line-height: 2em;
  margin-left: 1.5em;
  color: #ffffff;
}

.intervalleH2 {
  z-index: 1003;
  width: 29em;
  height: 1em;
  margin: 0;
  padding: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);

}

.buttonBox {
  width: 29em;
  height: 4em;
  z-index: 1090;
  background-color: #3465a4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.buttonBox:hover,
.buttonBox2:hover {
  color: #ffffff;
  background-color: #3465a4;
}