form {
    width: auto;
    height: auto;
}

.mainBox2 {
    z-index: 1100;
    width: 29em;
    height: 48em;
}

/*
.mainBox3 {
    width: 29em;
    height: 43em;
    background-color: #3465a4;
    display: flex;
    align-items: center;
    justify-content: center;
}
*/
.itemCard {
    display: flex;
    flex-direction: column;
    width: 27em;
    height: 41em;
    background-color: #b4c7dc;
}

.firstCard {
    display: flex;
    flex-direction: row;
    width: 27em;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9em;
    min-height: 12em;
    padding: 1.25em;
}

.firstCard_D {
    width: 12em;
    flex-direction: column;
    margin: 1.25em;
}

.secondCard,
.fourthCard {
    display: flex;
    flex-direction: column;
    width: 27em;
}

.thirdCard {
    display: flex;
    flex-direction: row;
    width: 24.5em;
    margin: 1.25em;
}

.fifthCard {
    display: flex;
    flex-direction: column;
    width: 24.5em;
    margin: 1.25em;
}

.sixthCard {
    display: flex;
    flex-direction: row;
    width: 24em;
    margin: 0;
    margin-left: 1em;
}

.cardLine,
.cardLine2 {
    display: flex;
    flex-direction: column;
    height: 4em;
    width: 12.5em;
}

.cardLine3 {
    display: flex;
    flex-direction: column;
    height: 4em;
    width: 25.5em;
}


.cardLine2 {
    margin-right: 1em;
}

.edit {
    height: 3.00em;
    margin-right: 0.5em;
    margin-top: 0.3em;
    margin-left: 1em;
}

.edit:last-child>div>input {
    height: 1.1em;
    padding: 0;
    margin: 0.25em;
    line-height: 1em;
}

.edit:last-child>div>p {
    height: 0.75em;
    padding: 0;
    margin: 0;
    margin-bottom: 0.25em;
    line-height: 1em;
}

.edit2 {
    height: 3.00em;
    margin-left: 1em;
    margin-top: 0.3em;

}

.edit2>div {
    width: 22.5em;
}

.edit2:last-child>div>input {
    height: 1em;
    padding: 0;
    margin: 0.25em;
    line-height: 1em;
}

.edit2:last-child>div>p {
    height: 0.75em;
    padding: 0;
    margin: 0;
    margin-bottom: 0.25em;
    line-height: 1em;
}

.edit3 {
    height: 3.00em;
    margin-right: 0.5em;
    margin-top: 0.3em;
    margin-left: 1em;
    /*    border-color: black !important; */
}

/*
.edit3:last-child>div {
    display: inline-flex;
    .MuiOutlinedInput-notchedOutline;
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline;
}
*/
.edit3:last-child>div {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    /*    border: 0; */
    vertical-align: top;
    height: 1.1em;
}

.edit3:last-child>div>div>input {
    /*    height: 1.1em;
    padding: 0;
    /*    margin: 0.25em; */
    line-height: 1em;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    /*    border: 0; */
    border: solid rgba(0, 0, 0, 0.23) 1px;
    border-radius: 4px;
    box-sizing: content-box;
    background: none;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
}

.edit3:last-child>div>div>input:hover {
    border: solid rgba(0, 0, 0, 0.87) 1px;
    cursor: pointer;
}

.edit3:last-child>div>div {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
}

.edit4,
.react-datepicker__input-container {
    border-color: rgba(0, 0, 0, 1);
}

/*
.edit3:last-child>div>div>input {
    color: currentColor;
    background: none;
    padding: 0;
    margin: 0.25em;
    line-height: 1.1em;
}
*/
.label {
    display: none !important;
    visibility: hidden;
}


.descriptif,
.cardLine>p {
    font-size: 1em;
    height: 1.25em;
    padding: 0;
    margin: 0;
}

.buttonBox {
    width: 29em;
    height: 4em;
    background-color: #3465a4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
}

.buttonBox2 {
    color: #ffffff;
    background-color: #3465a4;
}


.buttonBox2.css-1rnpvhj-MuiButtonBase-root-MuiButton-root:hover {
    color: #ffffff;
    background-color: #045e7f !important;
}

/*
.floatting-label {
    position: absolute;
    top: 8px;
    left: 0;
    pointer-events: none;
    transform-origin: left;
    transition: transform 250 ms;
}

.input:focus+.floatting-label,
.input:not(:placeholder-shown)+.floatting-label {
    transform: translateY(-100%) scale(0.75);
}
*/