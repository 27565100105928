/*

.mainBox2 {
    width: 29em;
    height: 48em;
}


.mainBox3 {
    width: 29em;
    height: 43em;
    background-color: #3465a4;
    display: flex;
    align-items: center;
    justify-content: center;
}

*/
.itemCard {
    display: flex;
    flex-direction: column;
    width: 27em;
    height: 41em;
    background-color: #b4c7dc;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9em;
    min-height: 12em;
    padding: 1.25em;
}

.cardLineEdit {
    height: 4em;
}

.intitule {
    height: 1.25em;
}

.valeur {
    height: 1.4375em !important;
    font-size: 1.2em !important;
    font-weight: 500 !important;
    /*    margin-left: 1em !important; */
    color: #ffffff;
    background-color: #3465a4 !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    margin-top: 0.2em !important;
    white-space: nowrap;
    overflow: hidden;
}

.valeur2 {
    height: 1.4375em !important;
    font-size: 1.2em !important;
    font-weight: 500 !important;
    /*    margin-left: 1em !important; */
    color: #ffffff;
    background-color: #3465a4 !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    margin-top: 0.2em !important;
    white-space: nowrap;
    overflow: hidden;
}

/*
.firstCard,
.thirdCard,
.fourthCard {
    margin: 1.25em;
}

.firstCard {
    width: 13em;
}


.buttonBox {
    width: 29em;
    height: 4em;
    background-color: #3465a4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.buttonBox2 {
    color: #ffffff;
    background-color: #3465a4;
}
*/