.App {
  z-index: 0;
  display: flex;
  flex-direction: row;
  width: 36em;
  height: 57em;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
  border: 3px solid #3465a4;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 4px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.aDroite {
  z-index: 1;
  width: 3em;
  height: 100%;
  margin: 0;
  padding: 0;
  /*
  position: fixed;
  top: 0;
  right: 0;
  */
  background-color: rgba(255, 255, 255, 0);
}

.intervalleV {
  z-index: 2;
  width: calc(2em + 1px);
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #3465a4;
  position: fixed;
  top: 0;
  right: -1px;
}

.aGauche {
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 33em;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
}


.mainBox {
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 110;
  top: 4em;
  width: 33em;
  height: 48em;
  background-color: rgba(255, 255, 255, 0);
  /*
  left: 0;
  background-color: #ffffff;
  */
}

.aDroite2 {
  z-index: 6;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 4em;
  height: 48em;
  margin: 0;
  padding: 0;
  top: 4em;
  right: 3em;
  background-color: rgba(255, 255, 255, 0);
}

.intervalleV2 {
  z-index: 2;
  width: 1em;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
}


a {
  text-decoration-line: none !important;
  color: white;
}

/*

.intervalleH {
  width: 33em;
  height: 1em;
  margin: 0;
  padding: 0;
  left: 0;
  background-color: #ffffff;
}

.horizontal {
  display: flex;
  flex-direction: "row";
  width: 33em;
  height: 46em;
}

.header {
  top: 0;
  left: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/